import React, { useState, useEffect } from 'react';
import { signInWithCustomToken, updatePassword } from 'firebase/auth';
import { auth } from '../../../firebase';
import ConfettiExplosion from 'react-confetti-explosion';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '../../../Assets/arrow-left-solid.svg';

function PasswordSetup() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (!token) {
      navigate('/');
      return;
    }

    setIsLoading(true);
    signInWithCustomToken(auth, token)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setError('Invalid or expired token. Please try again.');
        setIsLoading(false);
      });
  }, [location, navigate]);

  const goBack = () => {
    navigate('/');
  };

  if (isLoading) {
    return <div className="spinner"></div>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const user = auth.currentUser;
      if (user) {
        await updatePassword(user, password);
        setSuccess(true);
        setTimeout(() => {
          navigate('/');
        }, 4000);
      } else {
        setError('User not authenticated');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  if (success) {
    return (
      <div className="signup">
        <ConfettiExplosion />
        <h2>¡Contraseña establecida con éxito!</h2>
        <p>Has iniciado sesión. Redirigiendo al dashboard...</p>
      </div>
    );
  }

  return (
    <div className="signup" style={styles.signup}>
      {error && <p className="error" style={styles.error}>{error}</p>}
      <button className="back-button" onClick={goBack} style={styles.backButton}>
        <img src={ArrowBackIcon} alt="Back" style={styles.backButtonImg} />
      </button>
      <h1>Establece tu contraseña</h1>
      <h5>Crea una contraseña segura para tu cuenta</h5>
      <form onSubmit={handleSubmit} style={styles.form}>
        <input
          type="password"
          id="password"
          placeholder="Nueva contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          style={styles.input}
        />
        <input
          type="password"
          id="confirmPassword"
          placeholder="Confirmar contraseña"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          style={styles.input}
        />
        <button type="submit" style={styles.button}>
          Establecer contraseña
        </button>
      </form>
    </div>
  );
}

const styles = {
  signup: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '10px',
    margin: '5px 0',
    borderRadius: '7px',
    border: 'none',
    backgroundColor: '#f3f3f3',
  },
  button: {
    padding: '15px 0',
    marginTop: '20px',
    fontSize: '16px',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#000',
    border: 'none',
    borderRadius: '7px',
    cursor: 'pointer',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  error: {
    color: 'red',
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '7px',
    backgroundColor: '#f3f3f3',
    cursor: 'pointer',
    border: 'none',
  },
  backButtonImg: {
    width: '50%',
    height: 'auto',
  },
};

export default PasswordSetup;
