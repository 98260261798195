import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/HomePage.css';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import Sidebar from './Sidebar'; // Ensure the path is correct
import Modal from 'react-modal';
import {auth, db} from '../../../firebase';
import Logo from '../../../Assets/wai-logo-black.png';
import Spinner from '../../../spinner.css';

const HomePage = () => {
  const navigate = useNavigate();
  const [brandProfile, setBrandProfile] = useState(null);
  const [products, setProducts] = useState([]);
  const [activeLink, setActiveLink] = useState('dashboard');
  const brandNameRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [salesData, setSalesData] = useState({
    totalSales: 0,
    totalVolume: 0,
    monthlySales: 0,
    monthlyVolume: 0,
    salesPercentageChange: null,
    volumePercentageChange: null
  });
  const [liquidationData, setLiquidationData] = useState({
    totalLiquidatedToday: 0,
    totalToLiquidate: 0
  });
  const [error, setError] = useState(null);
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const [activeSection, setActiveSection] = useState('dashboard');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 750) {
        setShowLeftSidebar(false);
      } else {
        setShowLeftSidebar(true);
      }
    };

    window.addEventListener('resize', handleResize);
    // Set initial state based on window size
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    const fetchBrandData = async () => {
      if (!auth.currentUser) {
        return;
      }
      setLoading(true);
      const brandsUserRef = collection(db, 'brands_user');
      const q = query(brandsUserRef, where('uid', '==', auth.currentUser.uid));
      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const brandDocSnap = querySnapshot.docs[0];
          setBrandProfile(brandDocSnap.data());
          brandNameRef.current = brandDocSnap.data().brand_name;
          console.log("brandDocSnap: ", brandDocSnap.data());
          const today = new Date();
          const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
          const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

          // Fetch previous month's data
          const previousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
          const previousMonthFirstDay = new Date(previousMonth.getFullYear(), previousMonth.getMonth(), 1);
          const previousMonthLastDay = new Date(previousMonth.getFullYear(), previousMonth.getMonth() + 1, 0);

          // Previous Month Sales and Volume
          const previousMonthSalesQuery = query(collection(db, 'brand_sales'), 
            where('brand_uid', '==', auth.currentUser.uid),
            where('payment_status', '==', 'APPROVED')
          );
          const previousMonthSalesSnapshot = await getDocs(previousMonthSalesQuery);
          console.log("previousMonthSalesSnapshot: ", previousMonthSalesSnapshot);
          let previousMonthSales = 0;
          let previousMonthVolume = 0;
          previousMonthSalesSnapshot.forEach(doc => {
            const docData = doc.data();
            const docDate = docData.created_at.toDate();
            if (docDate >= previousMonthFirstDay && docDate <= previousMonthLastDay) {
              previousMonthSales += docData.total_products;
              previousMonthVolume += docData.cart_items.length;
            }
          });

          // Total Sales and Volume
          const salesQuery = query(collection(db, 'brand_sales'), 
            where('brand_uid', '==', auth.currentUser.uid),
            where('payment_status', '==', 'APPROVED')
          );
          let salesSnapshot;
          try {
            salesSnapshot = await getDocs(salesQuery);
          } catch (error) {
            console.error("Failed to fetch documents:", error);
            return;
          }

          let totalSales = 0;
          let totalVolume = 0;
          let monthlySales = 0;
          let monthlyVolume = 0;

          salesSnapshot.forEach(doc => {
            const docData = doc.data();

            console.log("data: ", docData);
            
            // Use total_products instead of total_product, and provide a fallback to 0
            const saleAmount = docData.total_products || 0;
            console.log("Sale amount:", saleAmount);
            
            totalSales += saleAmount;
            
            // Handle undefined values properly in volumes
            const volume = Number(docData.cart_items?.length || 0);

            totalVolume += volume;

            const docDate = docData.created_at.toDate();
            if (docDate >= firstDayOfMonth && docDate <= lastDayOfMonth) {
              monthlySales += saleAmount;
              monthlyVolume += volume;
            }
          });
          
          // Calculate percentage changes
          const salesPercentageChange = previousMonthSales ? ((monthlySales - previousMonthSales) / previousMonthSales) * 100 : null;
          const volumePercentageChange = previousMonthVolume ? ((monthlyVolume - previousMonthVolume) / previousMonthVolume) * 100 : null;

          setSalesData({
            totalSales,
            totalVolume,
            monthlySales,
            monthlyVolume,
            salesPercentageChange,
            volumePercentageChange
          });

          // Fetch liquidation data
          const todayDate = new Date();
          const totalLiquidatedToday = await calculateTodayLiquidation(todayDate);
          const totalToLiquidate = await calculatePendingLiquidation();

          setLiquidationData({
            totalLiquidatedToday: totalLiquidatedToday,
            totalToLiquidate: totalToLiquidate
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Failed to fetch brand data:", error.message, "Error code:", error.code, "Stack trace:", error.stack);
      }
    };

    const calculateTodayLiquidation = async (date) => {
      const startOfDay = new Date(date);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(date);
      endOfDay.setHours(23, 59, 59, 999);
      
      const q = query(
        collection(db, 'liquidation_calendar_table'),
        where('brand_uid', '==', auth.currentUser.uid),
        where('liquidated_date', '>=', startOfDay),
        where('liquidated_date', '<=', endOfDay),
      );
      const querySnapshot = await getDocs(q);
      console.log("querySnapshot: ", querySnapshot.docs.length);

      let total = 0;
      querySnapshot.docs.forEach((doc) => {
        const docData = doc.data();
        console.log("docData: ", docData);
        if (docData.liquidation_status === 'APPROVED') {
          total += docData.liquidation_amount;
        }
      });
      return numberFormat(total);
    };

    const calculatePendingLiquidation = async () => {
      const q = query(
        collection(db, 'liquidation_calendar_table'),
        where('brand_uid', '==', auth.currentUser.uid),
        where('liquidation_status', '==', 'PENDING')
      );
      const querySnapshot = await getDocs(q);

      let total = 0;
      querySnapshot.docs.forEach((doc) => {
        const docData = doc.data();
        total += docData.liquidation_amount;
      });
      return numberFormat(total);
    };

    fetchBrandData();
  }, [db, auth.currentUser]);

  const numberFormat = (value) =>
  new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS'
  }).format(value);

  const handleSignOut = async () => {
    closeModal();
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleNavigation = (path) => {
    setActiveLink(path);
    navigate(`/${path}`);
  };

  return (
    <div className="content">
      {loading && <div className="spinner-container">
        <div className="spinner"></div>
      </div>}
      {!loading && <div className="home-page-container">
        {/* <Sidebar 
          activeLink={activeLink} 
          handleNavigation={handleNavigation} 
        /> */}
        <div className="main-content">
          <div className="user-profile-container">
          { !showLeftSidebar && <div className="user-profile-sidebar">
              <div className='logo' style={{width:'140px', height:'60px', alignItems:'left', justifyContent:'left'}}>
                <img src={Logo} className='logo-img' style={{ width: '100%', height: '100%',alignItems:'left', justifyContent:'left', objectFit: 'contain', padding:'0px' }} />
              </div>
              <ul className="user-profile-sidebar-links">
                <li className={activeSection === 'dashboard' ? 'active' : ''} onClick={() => handleNavigation('dashboard')}>Dashboard</li>
                <li className={activeSection === 'ventas' ? 'active' : ''} onClick={() => handleNavigation('ventas')}>Ventas</li>
                <li className={activeSection === 'ajustes' ? 'active' : ''} onClick={() => handleNavigation('ajustes')}>Ajustes</li>
                <li onClick={openModal}>Cerrar Sesion</li>
              </ul>
            </div>}
            {error ? (
              <div>
                {showLeftSidebar && <div className="user-profile-sidebar">
                  <ul className="user-profile-sidebar-links">
                      <li className={activeSection === 'dashboard' ? 'active' : ''} onClick={() => handleNavigation('dashboard')}>Dashboard</li>
                      <li className={activeSection === 'ventas' ? 'active' : ''} onClick={() => handleNavigation('ventas')}>Ventas</li>
                      <li className={activeSection === 'ajustes' ? 'active' : ''} onClick={() => handleNavigation('ajustes')}>Ajustes</li>
                    <li onClick={openModal}>Cerrar Sesion</li>
                  </ul>
                </div>}
                <div>Error: {error}</div>
              </div>
            ) : (
              <div className="user-profile-settings">
                <h1 style={{ fontWeight: 900}}>¡Hola, {brandNameRef.current}! 🚀</h1>
                {showLeftSidebar && <div className="user-profile-sidebar">
                  <ul className="user-profile-sidebar-links">
                      <li className={activeSection === 'dashboard' ? 'active' : ''} onClick={() => handleNavigation('dashboard')}>Dashboard</li>
                      <li className={activeSection === 'ventas' ? 'active' : ''} onClick={() => handleNavigation('ventas')}>Ventas</li>
                      <li className={activeSection === 'ajustes' ? 'active' : ''} onClick={() => handleNavigation('ajustes')}>Ajustes</li>
                    <li onClick={openModal}>Cerrar Sesion</li>
                  </ul>
                </div>}
                {/* Liquidation Data */}
                <div className="liquidation-container">
                  <div className="liquidation-card">
                    <h2>Dinero liquidado hoy</h2>
                    <p>{liquidationData.totalLiquidatedToday}</p>
                  </div>
                  <div className="liquidation-card">
                    <h2>Dinero a liquidar</h2>
                    <p>{liquidationData.totalToLiquidate}</p>
                  </div>
                </div>

                <h2 className="monthly-title">Total</h2>
                <div className="card-container">
                  <div className="card-left">
                    <div className="card-content">
                      <h2>Total Ventas</h2>
                      <p>{numberFormat(salesData.totalSales)}</p>
                    </div>
                  </div>
                  <div className="card-right">
                    <div className="card-content">
                      <h2>Total Volumen</h2>
                      <p>{salesData.totalVolume.toLocaleString()}</p>
                    </div>
                  </div>
                </div>

                {/* Adding the title "Este mes" */}
                <h2 className="monthly-title">Este mes</h2>
                <div className="card-container">
                  <div className="card-left">
                    <div className="card-content">
                      <h2>Total Ventas</h2>
                      <p>{numberFormat(salesData.monthlySales)}</p>
                      {salesData.salesPercentageChange !== null && (
                        <p className='card-left-percentage' style={{ fontSize: '.9rem', color: salesData.salesPercentageChange >= 0 ? '#42ED57' : 'red' }}>
                          {salesData.salesPercentageChange >= 0 ? '↑' : '↓'} {Math.abs(salesData.salesPercentageChange).toFixed(2)}%
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="card-right">
                    <div className="card-content">
                      <h2>Total Volumen</h2>
                      <p>{salesData.monthlyVolume.toLocaleString()}</p>
                      {salesData.volumePercentageChange !== null && (
                        <p className='card-left-percentage' style={{ fontSize: '.9rem', color: salesData.volumePercentageChange >= 0 ? '#42ED57' : 'red' }}>
                          {salesData.volumePercentageChange >= 0 ? '↑' : '↓'} {Math.abs(salesData.volumePercentageChange).toFixed(2)}%
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="button-container">
                  <button className='button-container-normal'>Mas Proximamente..</button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Sign Out"
        className="user-profile-modal-sign-out"
        overlayClassName="user-profile-modal-sign-out-overlay"
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10000
          }
        }}
      >
        <div style={{marginTop: 0, backgroundColor: '#FFF', padding: '20px', borderRadius: '5px'}} className="user-profile-modal-sign-out-content">
          <h2 className="user-profile-modal-sign-out-header">Confirm Sign Out</h2>
          <p className="user-profile-modal-sign-out-text">Are you sure you want to sign out?</p>
          <div className="user-profile-modal-sign-out-buttons">
            <button onClick={handleSignOut} className="user-profile-modal-sign-out-button" style={{ backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px', padding: '10px 20px', margin: '5px', cursor: 'pointer' }}>Yes</button>
            <button onClick={closeModal} className="user-profile-modal-sign-out-button" style={{ backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '4px', padding: '10px 20px', margin: '5px', cursor: 'pointer' }}>No</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default HomePage;
