import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, setDoc, query, where, collection, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import '../CSS/OnboardingForm.css';
import '../../AuthPages/CSS/SignUpPage.css';
import CreatableSelect from 'react-select/creatable';
import ArrowBackIcon from '../../../Assets/arrow-left-solid.svg';

const OnboardingForm = () => {
    const [stage, setStage] = useState(1);  // Initial stage is set to 1
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(true);
    const [bankOptions, setBankOptions] = useState([
        { value: "n1u", label: "n1u" },
        { value: "Banco Ciudad", label: "Banco Ciudad" },
        { value: "Banco Comafi", label: "Banco Comafi" },
        { value: "Banco Credicoop", label: "Banco Credicoop" },
        { value: "Banco de Galicia", label: "Banco de Galicia" },
        { value: "Banco de la Nacion", label: "Banco de la Nacion" },
        { value: "Banco Hipotecario", label: "Banco Hipotecario" },
        { value: "Banco Macro", label: "Banco Macro" },
        { value: "Banco Patagonia", label: "Banco Patagonia" },
        { value: "Banco Provincia", label: "Banco Provincia" },
        { value: "Banco Santander Río", label: "Banco Santander Río" },
        { value: "Banco Supervielle", label: "Banco Supervielle" },
        { value: "BBVA Argentina", label: "BBVA Argentina" },
        { value: "Belo", label: "Belo" },
        { value: "BNA +", label: "BNA +" },
        { value: "Brubank", label: "Brubank" },
        { value: "Cuenta DNI", label: "Cuenta DNI" },
        { value: "HSBC", label: "HSBC" },
        { value: "ICBC", label: "ICBC" },
        { value: "Lemon", label: "Lemon" },
        { value: "Mercado Pago", label: "Mercado Pago" },
        { value: "Naranja X", label: "Naranja X" },
        { value: "Personal Pay", label: "Personal Pay" },
        { value: "Uala", label: "Uala" }
    ]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    const auth = getAuth();
    const db = getFirestore();

    const handleBankChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'create-option') {
            // Add the new option to the list
            setBankOptions(prevOptions => [...prevOptions, newValue]);
        }
        
        const value = newValue ? newValue.value : '';
        setFormData(prevState => ({
            ...prevState,
            bank_account_name: value
        }));
    };

    const goBack = () => {
        if (stage > 1) {
            handlePrevious();
        } else {
            navigate('/');
        }
    }

    useEffect(() => {
        const checkOnboardingStatus = async () => {
            setLoading(true);
            const user = auth.currentUser;
            if (!user) {
                console.error("User not authenticated");
                setLoading(false);
                return;
            }

            const brandsCollection = collection(db, 'brands_user');
            const q = query(brandsCollection, where('uid', '==', user.uid));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const brandDoc = querySnapshot.docs[0];
                const data = brandDoc.data();
                if (data.onboarding_completed) {
                    navigate('/');
                    return;
                }
            }
            setLoading(false);
        };

        checkOnboardingStatus();
    }, [auth, db, navigate]);

    const handleChange = (e) => {
        const updatedData = {
            ...formData,
            [e.target.name]: e.target.value
        };
        setFormData(updatedData);
    };

    const handleNext = async () => {
        const nextStage = stage + 1;
        if (nextStage === 4) {
            // Pre-fill invoice data when reaching the final stage
            setFormData(prevData => ({
                ...prevData,
                invoice_name: prevData.invoice_name || prevData.brand_manager_name || '',
                invoice_address: prevData.invoice_address || 
                    `${prevData.brand_origin_address || ''} ${prevData.brand_origin_number || ''} ${prevData.brand_origin_floor || ''} ${prevData.brand_origin_locality || ''} ${prevData.brand_origin_city || ''} ${prevData.brand_origin_province || ''} ${prevData.brand_origin_postal_code || ''}`.trim(),
                invoice_cuit: prevData.invoice_cuit || prevData.bank_account_cuit || ''
            }));
        }
        setStage(nextStage);
    };

    const handlePrevious = async () => {
        const prevStage = stage - 1;
        setStage(prevStage);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const user = auth.currentUser;
        if (!user) {
            console.error("User not authenticated");
            setIsSubmitting(false);
            return;
        }

        const brandsCollection = collection(db, 'brands_user');
        const q = query(brandsCollection, where('uid', '==', user.uid));
        const querySnapshot = await getDocs(q);
        console.log("init");

        if (!querySnapshot.empty) {
            const brandDocRef = querySnapshot.docs[0].ref;
            const privateCollectionRef = collection(brandDocRef, 'private');

            try {
                // Create brand_data document
                const brandDataRef = doc(privateCollectionRef, 'brand_data');
                const brandData = {};
                const brandFields = [
                    'brand_manager_name', 'brand_origin_address', 'brand_origin_city',
                    'brand_origin_floor', 'brand_origin_locality', 'brand_origin_number',
                    'brand_origin_postal_code', 'brand_origin_province', 'brand_phone'
                ];
                brandFields.forEach(field => {
                    if (formData[field]) {
                        brandData[field] = formData[field];
                    }
                });
                // Add uid field
                brandData.uid = user.uid;
                await setDoc(brandDataRef, brandData);

                // Create bank_data document
                const bankDataRef = doc(privateCollectionRef, 'bank_data');
                const bankData = {};
                const bankFields = [
                    'bank_account_cuit', 'bank_account_dni', 'bank_account_name',
                    'bank_account_owner_name', 'bank_transfer_number_id'
                ];
                bankFields.forEach(field => {
                    if (formData[field]) {
                        bankData[field] = formData[field];
                    }
                });
                // Add uid field
                bankData.uid = user.uid;
                await setDoc(bankDataRef, bankData);

                // Create invoice_data document
                const invoiceDataRef = doc(privateCollectionRef, 'invoice_data');
                const invoiceData = {
                    invoice_name: formData.invoice_name || '',
                    invoice_address: formData.invoice_address || '',
                    invoice_cuit: formData.invoice_cuit || '',
                    uid: user.uid
                };
                await setDoc(invoiceDataRef, invoiceData);

                // Update main brand document
                await setDoc(brandDocRef, {
                    onboarding_completed: true
                }, { merge: true });

                console.log("Onboarding data submitted successfully");

                // Force refresh the user's auth state
                await user.getIdToken(true);
                
                // Navigate to the dashboard
                navigate('/dashboard');
            } catch (error) {
                console.error("Error submitting onboarding data:", error);
                setIsSubmitting(false);
            }
        } else {
            console.log("no brand");
            setIsSubmitting(false);
        }

    };

    return (
        <div className="signup onboarding-container">
            {loading ? (
                <div className="spinner"></div>
            ) : (
                <>
                    {stage > 1 && !isSubmitting && (
                        <button style={{width: '40px', height: '40px'}} className="back-button" onClick={goBack}>
                            <img style={{width: '50%'}} src={ArrowBackIcon} alt="Back" />
                        </button>
                    )}
                    {stage === 1 && (
                        <form onSubmit={(e) => { e.preventDefault(); handleNext(); }}>
                            <h2>Datos del Administrador</h2>
                            <input type="text" name="brand_manager_name" placeholder="Nombre y Apellido del Administrador" value={formData.brand_manager_name || ''} onChange={handleChange} />
                            <button type="submit" className="primary">Next</button>
                        </form>
                    )}
                    {stage === 2 && (
                        <form onSubmit={(e) => { e.preventDefault(); handleNext(); }}>
                            <h2>Datos Bancarios</h2>
                            <input type="text" name="bank_account_cuit" placeholder="CUIT/CUIL" value={formData.bank_account_cuit || ''} onChange={handleChange} />
                            <input type="text" name="bank_account_dni" placeholder="DNI" value={formData.bank_account_dni || ''} onChange={handleChange} />
                            <div style={{marginTop: '10px'}} className="input-group">
                                <label>
                                    <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>Banco:</span>
                                    <CreatableSelect
                                        options={bankOptions}
                                        value={bankOptions.find(option => option.value === formData.bank_account_name) || null}
                                        onChange={handleBankChange}
                                        placeholder="Seleccione o escriba el nombre del banco"
                                    />
                                </label>
                            </div>
                            <input type="text" name="bank_account_owner_name" placeholder="Nombre del Titular" value={formData.bank_account_owner_name || ''} onChange={handleChange} />
                            <input type="text" name="bank_transfer_number_id" placeholder="CBU o Alias" value={formData.bank_transfer_number_id || ''} onChange={handleChange} />
                            <div className="button-group">
                                <button type="submit" className="primary">Next</button>
                            </div>
                        </form>
                    )}
                    {stage === 3 && (
                        <form onSubmit={(e) => { e.preventDefault(); handleNext(); }}>
                            <h2>Datos de Envio</h2>
                            <input type="text" name="brand_phone" placeholder="Numero de Teléfono" value={formData.brand_phone || ''} onChange={handleChange} />
                            <input type="text" name="brand_origin_address" placeholder="Calle" value={formData.brand_origin_address || ''} onChange={handleChange} />
                            <input type="text" name="brand_origin_number" placeholder="Numero de Calle" value={formData.brand_origin_number || ''} onChange={handleChange} />
                            <input type="text" name="brand_origin_floor" placeholder="Piso" value={formData.brand_origin_floor || ''} onChange={handleChange} />
                            <input type="text" name="brand_origin_locality" placeholder="Localidad" value={formData.brand_origin_locality || ''} onChange={handleChange} />
                            <input type="text" name="brand_origin_city" placeholder="Ciudad" value={formData.brand_origin_city || ''} onChange={handleChange} />
                            <select name="brand_origin_province" value={formData.brand_origin_province || ''} onChange={handleChange}
                                style={{
                                    width: 'calc(100% - 20px)',
                                    padding: '10px',
                                    margin: '10px 0',
                                    border: 'none',
                                    borderRadius: '4px',
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    backgroundColor: '#f0f0f0',
                                    appearance: 'none',
                                    backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'right 10px top 50%',
                                    backgroundSize: '12px auto'
                                }}
                            >
                                <option value="">Seleccionar Provincia</option>
                                {["Buenos Aires", "Ciudad Autónoma de Buenos Aires", "Catamarca", "Chaco", "Chubut", "Córdoba", "Corrientes", "Entre Ríos", "Formosa", "Jujuy", "La Pampa", "La Rioja", "Mendoza", "Misiones", "Neuquén", "Río Negro", "Salta", "San Juan", "San Luis", "Santa Cruz", "Santa Fe", "Santiago del Estero", "Tierra del Fuego", "Tucumán"].map((province) => (
                                    <option key={province} value={province}>{province}</option>
                                ))}
                            </select>
                            <input type="text" name="brand_origin_postal_code" placeholder="Código Postal" value={formData.brand_origin_postal_code || ''} onChange={handleChange} />
                            <div className="button-group">
                                <button type="submit" className="primary">Next</button>
                            </div>
                        </form>
                    )}
                    {stage === 4 && (
                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                            <h2>Datos de Facturacion</h2>
                            <input 
                                type="text" 
                                name="invoice_name" 
                                placeholder="Apellido y Nombre" 
                                value={formData.invoice_name || ''} 
                                onChange={handleChange} 
                            />

                            <input 
                                type="text" 
                                name="invoice_address" 
                                placeholder="Domicilio" 
                                value={formData.invoice_address || ''} 
                                onChange={handleChange} 
                            />

                            <input 
                                type="text" 
                                name="invoice_cuit" 
                                placeholder="CUIT / CUIL" 
                                value={formData.invoice_cuit || ''} 
                                onChange={handleChange} 
                            />

                            <div className="button-group">
                                {!isSubmitting && (
                                    <button type="submit" className="primary">Submit</button>
                                )}
                                {isSubmitting && (
                                    <div className="spinner"></div>
                                )}
                            </div>
                        </form>
                    )}
                </>
            )}
        </div>
    );
};

export default OnboardingForm;