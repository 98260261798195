import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Pages/Landing.css';
import Logo from '../Assets/wai-logo-white.png';

function HomePage() {
  const [email, setEmail] = useState(""); 
  const navigate = useNavigate();  // <-- Change this line

  return (
    <div className="mac-book-pro-14-1">
        <div className="navbar-landing" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1%', marginBottom: '1%', marginLeft: '2%', marginRight: '2%'}}>
            <div className="logo" style={{width:'100px', height:'100px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => window.location.reload()}>
                <img src={Logo} alt="Logo" className='logo-img' style={{ width: '80%', height: '80%', objectFit: 'contain' }} />
            </div>
            <div style={{marginLeft: 'auto'}}>
                <button onClick={() => navigate('/login')} style={{textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)', fontSize: '16px', color: 'white', background: 'transparent', border: 'none', borderRadius: '5px', cursor: 'pointer', fontFamily: 'Raleway', fontWeight: 'bold', fontStyle: 'italic', marginLeft: 'auto'}}>Log In</button>
            </div>
        </div>
        
        <div className='landing-page-text' style={{marginTop: '80px', marginLeft: '100px'}}>
            <h1 className="title">Una nueva forma<br /><strong>de vender ropa online</strong></h1>
            <h5 className="subtitle">
            Una plataforma con las mejores marcas de Argentina, atraves de  <br /> Inteligencia artificial creamos un ecosistema <strong>UNICO</strong>
            </h5>
            
            <div className="contact-button">
                <input 
                    className="tu-email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    placeholder="Tu email" 
                />

                <div className="contact-button2" style={{cursor: "pointer"}}>
                    <button 
                        style={{cursor: "pointer"}}
                        className="crear-link" 
                        onClick={() => navigate(`/join?email=${email}`)}
                    >
                        Crear Tienda
                    </button>
                </div>
            </div>
        </div>
    </div>
  );
}

export default HomePage;
