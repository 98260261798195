import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/SalesPage.css'; // Ensure this path is correct
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import ImageSizeSlider from './ImageSizeSlider'; // Ensure this path is correct
import Sidebar from './Sidebar'; // Import the Sidebar component
import {auth, db} from '../../../firebase'; // Adjust imports based on your setup
import Logo from '../../../Assets/wai-logo-black.png';
import '../CSS/HomePage.css';
import '../../../spinner.css';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify'; // Add this import at the top of the file
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify

const SalesPage = () => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeSection, setActiveSection] = useState('ventas');
  const [sales, setSales] = useState([]);
  const [expandedSaleId, setExpandedSaleId] = useState(null);
  const [itemData, setItemData] = useState({});
  const [imageSize, setImageSize] = useState(100); // State for image size
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('ajustes');
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 750) {
        setShowLeftSidebar(false);
      } else {
        setShowLeftSidebar(true);
      }
    };

    window.addEventListener('resize', handleResize);
    // Set initial state based on window size
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSignOut = async () => {
    closeModal();
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  useEffect(() => {

    const fetchSalesData = async () => {
      const user = auth.currentUser;
      setLoading(true);
      if (!user) {
        console.error("User not authenticated");
        return;
      }

      try {
        const profileQuery = query(collection(db, 'brands_user'), where('uid', '==', user.uid));
        const profileSnapshot = await getDocs(profileQuery);
        setProfileData(profileSnapshot.docs[0].data());
        const salesQuery = query(collection(db, 'brand_sales'), where('wearly_brand_id', '==', user.uid));
        const salesSnapshot = await getDocs(salesQuery);

        const salesData = await Promise.all(salesSnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const date = data.updated_at ? data.updated_at.toDate() : null;

          // Fetch user data for each sale
          let userData = null;
          if (data.user_id) {
            const userQuery = query(collection(db, 'users'), where('user_id', '==', data.user_id));
            const userSnapshot = await getDocs(userQuery);
            if (!userSnapshot.empty) {
              userData = userSnapshot.docs[0].data();
            }
          }

          return {
            id: doc.id,
            ...data,
            date,
            user: userData,
          };
        }));

        setSales(salesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching sales data: ", error);
        setLoading(false);
      }
    };

    const authUnsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        fetchSalesData();
      }
    });

    return () => authUnsubscribe();
  }, [auth, db, navigate]);

  const handleNavigation = (path) => {
    setActiveLink(path);
    navigate(`/${path}`);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const fetchItemData = async (saleId, cartItems) => {
    try {
      const itemQueries = cartItems.map(item => getDocs(query(collection(db, 'products'), where('wearly_item_id', '==', item.wearly_item_id))));
      const itemSnapshots = await Promise.all(itemQueries);

      const items = itemSnapshots.map(snapshot => snapshot.docs[0].data());
      setItemData(prevState => ({ ...prevState, [saleId]: items }));
    } catch (error) {
      console.error("Error fetching item data: ", error);
    }
  };

  const toggleDropdown = (saleId, cartItems) => {
    if (expandedSaleId === saleId) {
      setExpandedSaleId(null);
    } else {
      setExpandedSaleId(saleId);
      if (!itemData[saleId]) {
        fetchItemData(saleId, cartItems);
      }
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Link copiado al portapapeles!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch(err => {
      console.error('Error al copiar al portapapeles: ', err);
      toast.error('Error al copiar el link. Por favor, inténtalo de nuevo.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };
  return (
    <div className="content">
      <div className="home-page-container">
        <div className="main-content">
          <div className="user-profile-container">
            { !showLeftSidebar && <div className="user-profile-sidebar">
              <div className='logo' style={{width:'140px', height:'60px', alignItems:'left', justifyContent:'left'}}>
                <img src={Logo} className='logo-img' style={{ width: '100%', height: '100%',alignItems:'left', justifyContent:'left', objectFit: 'contain', padding:'0px' }} />
              </div>
              <ul className="user-profile-sidebar-links">
                <li className={activeSection === 'dashboard' ? 'active' : ''} onClick={() => handleNavigation('dashboard')}>Dashboard</li>
                <li className={activeSection === 'ventas' ? 'active' : ''} onClick={() => handleNavigation('ventas')}>Ventas</li>
                <li className={activeSection === 'ajustes' ? 'active' : ''} onClick={() => handleNavigation('ajustes')}>Ajustes</li>
                <li onClick={openModal}>Cerrar Sesion</li>
              </ul>
            </div>}
            <div className="user-profile-settings">
              <h1 style={{ fontWeight: 900}}>Tus Ventas</h1>
              {showLeftSidebar && <div className="user-profile-sidebar">
                <ul className="user-profile-sidebar-links">
                    <li className={activeSection === 'dashboard' ? 'active' : ''} onClick={() => handleNavigation('dashboard')}>Dashboard</li>
                    <li className={activeSection === 'ventas' ? 'active' : ''} onClick={() => handleNavigation('ventas')}>Ventas</li>
                    <li className={activeSection === 'ajustes' ? 'active' : ''} onClick={() => handleNavigation('ajustes')}>Ajustes</li>
                  <li onClick={openModal}>Cerrar Sesion</li>
                </ul>
              </div>}
              <div className="sales-list">
                {sales.length === 0 ? (
                  <>
                    <p>Aún no tienes ventas.<br/>Comparte tu perfil para ayudar a tus clientes a explorar tu tienda de la mejor manera.</p>
                    <button 
                      onClick={() => copyToClipboard(`https://wearlyapp.com/brand/${profileData.wearly_brand_id}`)}
                      style={{
                        backgroundColor: 'black',
                        color: 'white',
                        fontWeight: 'bold',
                        padding: '10px 50px',
                        borderRadius: '7px',
                        cursor: 'pointer',
                        border: 'none'
                      }}
                    >
                      Comparti tu Tienda!
                    </button>
                  </>
                ) : (
                  sales.map(sale => (
                    <div key={sale.id} className="sale-item">
                      <div className="sale-header">
                        <span className="sale-id">#{sale.purchase_uid}</span>
                        <span className="sale-date">{sale.date ? sale.date.toLocaleDateString() : 'N/A'}</span>
                      </div>
                      <div className="sale-body">
                        <span className="sale-client">{`${sale.user.first_name} ${sale.user.last_name}`}</span>
                        <span className="sale-total">${sale.amount ? sale.amount.toLocaleString() : 'N/A'}</span>
                      </div>
                      <div className="sale-footer">
                        <span className={`status ${sale.payment_status ? sale.payment_status.toLowerCase() : ''}`}>
                          {sale.payment_status}
                        </span>
                        <a className="view-items" onClick={() => toggleDropdown(sale.id, sale.cart_items)}>
                          {expandedSaleId === sale.id ? 'Ver menos' : 'Ver mas'}
                        </a>
                      </div>
                      {expandedSaleId === sale.id && (
                        <div className="dropdown-content">
                          {itemData[sale.id] && itemData[sale.id].length > 0 ? (
                            <>
                              <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Productos:</p>
                              <ul style={{ listStyleType: 'disc', marginLeft: '20px', paddingLeft: '20px', marginTop: '0px' }}>
                                {itemData[sale.id].map((item, index) => (
                                  <li key={index} style={{ listStyleType: 'disc', marginBottom: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      {item.name}
                                    </div>
                                  </li>
                                ))}
                              </ul>
                              <div style={{display: 'flex', flexDirection: 'row'}}>
                                <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Dirección:</p>
                                <p style={{ marginLeft: '10px', marginTop: '0px' }}>{[
                                  sale.user.user_address.user_address,
                                  sale.user.user_address.street_number,
                                  sale.user.user_address.apt_number && `Apt ${sale.user.user_address.apt_number}`,
                                  sale.user.user_address.floor && `Floor ${sale.user.user_address.floor}`,
                                  sale.user.user_address.neighborhood,
                                  sale.user.user_address.city,
                                  sale.user.user_address.province,
                                  sale.user.user_address.postal_code
                                ].filter(Boolean).join(', ')}</p>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'row'}}>
                                <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Email:</p>
                                <p style={{ marginTop: '0px', marginLeft: '10px' }}>{sale.user.email}</p>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'row'}}>
                                <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Phone:</p>
                                <p style={{ marginTop: '0px', marginLeft: '10px' }}>{sale.user.phone}</p>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'row'}}>
                                <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Sub Total:</p>
                                <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.total_products ? sale.total_products.toLocaleString() : 'N/A'}</p>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'row'}}>
                                <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Shipping:</p>
                                <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.shipping_cost ? sale.shipping_cost.toLocaleString() : 'N/A'}</p>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'row'}}>
                                <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Comisión:</p>
                                <p style={{ marginTop: '0px', marginLeft: '10px' }}>- ${sale.amount * 0.05 ? (sale.amount * 0.05).toLocaleString() : 'N/A'}</p>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'row'}}>
                                <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Total:</p>
                                <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.amount ? sale.amount.toLocaleString() : 'N/A'}</p>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'row'}}>
                                <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Total a Liquidar:</p>
                                <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.amount ? (sale.amount - (sale.amount * 0.05)).toLocaleString() : 'N/A'}</p>
                              </div>
                            </>
                          ) : (
                            <p>No products found for this sale.</p>
                          )}
                        </div>
                      )}
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Sign Out"
        className="user-profile-modal-sign-out"
        overlayClassName="user-profile-modal-sign-out-overlay"
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10000
          }
        }}
      >
        <div style={{marginTop: 0, backgroundColor: '#FFF', padding: '20px', borderRadius: '5px'}} className="user-profile-modal-sign-out-content">
          <h2 className="user-profile-modal-sign-out-header">Confirm Sign Out</h2>
          <p className="user-profile-modal-sign-out-text">Are you sure you want to sign out?</p>
          <div className="user-profile-modal-sign-out-buttons">
            <button onClick={handleSignOut} className="user-profile-modal-sign-out-button" style={{ backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px', padding: '10px 20px', margin: '5px', cursor: 'pointer' }}>Yes</button>
            <button onClick={closeModal} className="user-profile-modal-sign-out-button" style={{ backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '4px', padding: '10px 20px', margin: '5px', cursor: 'pointer' }}>No</button>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default SalesPage;
