import { useEffect, useState } from 'react';
import { auth, db } from './firebase'; // Adjust imports based on your setup
import { onSnapshot, query, where, collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { getIdTokenResult } from 'firebase/auth';

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [authChecking, setAuthChecking] = useState(true);
  const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        const idTokenResult = await getIdTokenResult(user);
        setUserRole(idTokenResult.claims.role);

        const brandsCollection = collection(db, 'brands_user');
        const q = query(brandsCollection, where('uid', '==', user.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const brandDoc = querySnapshot.docs[0];
          const unsubscribeDoc = onSnapshot(brandDoc.ref, (doc) => {
            if (doc.exists()) {
              const data = doc.data();
              setOnboardingStatus(data.onboarding_completed || false);
            } else {
              setOnboardingStatus(false);
            }
          });
        } else {
          setOnboardingStatus(false);
        }
      } else {
        setUser(null);
        setUserRole('');
        setOnboardingStatus(null);
      }
      setAuthChecking(false);
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  return { user, authChecking, onboardingStatus, userRole };
};

export { useAuth };