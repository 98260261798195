import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, updateProfile } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, setDoc, doc, getFirestore, getDocs, query, where, serverTimestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import ArrowBackIcon from '../../../Assets/arrow-left-solid.svg';
import '../CSS/SignUpPage.css';
import ConfettiExplosion from 'react-confetti-explosion';
import '../../../spinner.css';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';

const SignUpPage = () => {
  const [brandName, setBrandName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false); // New state to control form visibility
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [monthlyVolume, setMonthlyVolume] = useState('');
  const [storeLink, setStoreLink] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [tokenTiendaNube, setTokenTiendaNube] = useState('');
  const [socialLink1, setSocialLink1] = useState('');
  const [socialLink2, setSocialLink2] = useState('');
  const emailFromQuery = queryParams.get("email");
  const [email, setEmail] = useState(emailFromQuery || '');  
  const [code, setCode] = useState('');

  // add cloud func
  const functions = getFunctions();
  const addBrandToWaitlist = httpsCallable(functions, 'add_brand_to_waitlist');
  const [showWaitListForm, setShowWaitListForm] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const integrationsList = [
    { value: "WooComerce", label: "WooComerce" },
    { value: "MercadoShops", label: "MercadoShops" },
    { value: "Empretienda", label: "Empretienda" },
    { value: "WordPress", label: "WordPress" },
    { value: "Shopify", label: "Shopify" },
    { value: "Vtex", label: "Vtex" },
    { value: "Grid Web Engine", label: "Grid" },
    { value: "Desarrollo Propio", label: "Desarrollo Propio" },
    { value: "TiendaNube", label: "TiendaNube" },
    { value: "Otro", label: "Otro" }
  ];

  const countryList = [
    { value: "AR", label: "🇦🇷 Argentina" },
    { value: "BO", label: "🇧🇴 Bolivia" },
    { value: "BR", label: "🇧🇷 Brazil" },
    { value: "CL", label: "🇨🇱 Chile" },
    { value: "CO", label: "🇨🇴 Colombia" },
    { value: "CR", label: "🇨🇷 Costa Rica" },
    { value: "CU", label: "🇨🇺 Cuba" },
    { value: "DO", label: "🇩🇴 Dominican Republic" },
    { value: "EC", label: "🇪🇨 Ecuador" },
    { value: "SV", label: "🇸🇻 El Salvador" },
    { value: "GT", label: "🇬🇹 Guatemala" },
    { value: "HT", label: "🇭🇹 Haiti" },
    { value: "HN", label: "🇭🇳 Honduras" },
    { value: "MX", label: "🇲🇽 Mexico" },
    { value: "NI", label: "🇳🇮 Nicaragua" },
    { value: "PA", label: "🇵🇦 Panama" },
    { value: "PY", label: "🇵🇾 Paraguay" },
    { value: "PE", label: "🇵🇪 Peru" },
    { value: "PR", label: "🇵🇷 Puerto Rico" },
    { value: "UY", label: "🇺🇾 Uruguay" },
    { value: "US", label: "🇺🇸 USA" },
    { value: "VE", label: "🇻🇪 Venezuela" },
    { value: "OT", label: "🌎 Otro" }
  ];

  useEffect(() => {
    const fetchCountry = async () => {
        const result = await axios.get('https://ipapi.co/json/');
        if (result.status == 200) {
            const country = result.data.country_name;
            const countryObj = countryList.find(c => c.value === result.data.country);
            if (countryObj) {
                setSelectedCountry([countryObj]);
            } else {
                setSelectedCountry([{ value: country, label: `🌎 ${country}` }]);
            }
        }
    };
    fetchCountry();
}, []);

  useEffect(() => {
    // Function to fetch the code
    const fetchCode = async () => {
        const params = new URLSearchParams(window.location.search);
        const codeParam = params.get('code');
        if (!codeParam) {
            console.error('Code parameter is missing in the URL');
            return;
        }
        
        setCode(codeParam);
        setTokenTiendaNube(codeParam);
        setShowWaitListForm(true);
        setSelectedIntegration({ value: "TiendaNube", label: "TiendaNube" });
    };

    fetchCode();
  }, []); // Empty dependency array means this effect runs once on mount


  const handleSignup = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    console.log("Starting...")

    if (selectedIntegration === null) {
      setError('Por favor, selecciona una integración');
      setLoading(false);
      return;
    }

    if (selectedIntegration.value === "TiendaNube" && tokenTiendaNube === '') {
      setError('Por favor, ingresa el código de TiendaNube');
      window.location.href = 'https://www.tiendanube.com/tienda-aplicaciones-nube/wearly';
      setLoading(false);
      return;
    }

    if (selectedCountry === null || selectedCountry.length === 0) {
      setError('Por favor, selecciona al menos un país');
      setLoading(false);
      return;
    }

    try {
      const result = await addBrandToWaitlist({ 
        brand_name: brandName, 
        brand_email: email, 
        monthly_volume: monthlyVolume, 
        store_link: storeLink, 
        social_link_1: socialLink1, 
        social_link_2: socialLink2, 
        brand_token_tn: tokenTiendaNube ? tokenTiendaNube : null,
        integration: selectedIntegration ? selectedIntegration.value : null,
        country: selectedCountry.map(country => country.value),
        phone_number: phoneNumber
      });
      console.log('Success:', result);
      setIsSubmitted(true);
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'An error occurred'); // Change this line
      setLoading(false);
      setIsSubmitted(false);
    }
  };  

  useEffect(() => {
    // Extract email directly from the URLSearchParams to ensure it works on refreshes or direct access.
    const emailFromQuery = new URLSearchParams(window.location.search).get("email");
    setEmail(emailFromQuery || ''); // Set to empty string if no email query param is found
  }, []);

const goBack = () => {
  if (showWaitListForm) {
    setShowWaitListForm(false);
  } else {
    navigate('/');
  }
}

const numberFormat = (value) =>
new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS'
}).format(value);

  return (
    <div className="signup">
      {!isSubmitted ? ( // Conditional rendering based on isSubmitted state
        <>
          {!loading && (
              <button className="back-button" onClick={goBack}>
                  <img src={ArrowBackIcon} alt="Back" />
              </button>
          )}
          <h1 style={{marginBottom: 0}}>Unite a Wearly</h1>
          <h5 style={{marginBottom: 30}}>Unite a la lista de marcas y uno de nuestros especialistas se pondrá en contacto contigo</h5>
          {!loading ? (
            <>
              {showWaitListForm ? (
                <>
                  <form onSubmit={handleSignup}>
                    {!loading && <input 
                        type="email" 
                        name="email" 
                        placeholder="Email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                    />}
                    {!loading && <input type="text" name="brandName" placeholder="Nombre de la marca" value={brandName} onChange={(e) => setBrandName(e.target.value)} required />}
                    {!loading && <input type="number" name="monthlyVolume" placeholder="Facturacion mensual aproximada" value={monthlyVolume} onChange={(e) => setMonthlyVolume(e.target.value)} required />}
                    {!loading && <input type="text" name="storeLink" placeholder="Link de tu tienda online" value={storeLink} onChange={(e) => setStoreLink(e.target.value)} required />}
                    {!loading && <input type="number" name="phoneNumber" placeholder="Numero de Telefono" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />}
                    {!loading && <input type="text" name="socialLink1" placeholder="Link de tus redes sociales #1 (opcional)" value={socialLink1} onChange={(e) => setSocialLink1(e.target.value)} />}
                    {!loading && <input type="text" name="socialLink2" placeholder="Link de tus redes sociales #2 (opcional)" value={socialLink2} onChange={(e) => setSocialLink2(e.target.value)} />}
                    {!loading && (
                      <CreatableSelect
                        options={countryList}
                        placeholder="Selecciona tu país"
                        value={selectedCountry}
                        onChange={setSelectedCountry}
                        isClearable
                        isMulti
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            marginTop: '20px'
                          })
                        }}
                      />
                    )}
                    {!loading && !code && (
                      <CreatableSelect
                        options={integrationsList}
                        placeholder="Selecciona tu integración"
                        value={selectedIntegration}
                        onChange={setSelectedIntegration}
                        isClearable
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            marginTop: '20px',
                            marginBottom: '40px'
                          })
                        }}
                      />
                    )}
                    {tokenTiendaNube && <p>Codigo de TiendaNube: {tokenTiendaNube}</p>}
                    <button type="submit" disabled={loading}>
                      {loading ? "Loading..." : "Unite"}
                    </button>
                  </form>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  <p className="terms-conditions">
                    Al hacer clic en "Unite a Wearly", aceptas los 
                    <a href="https://wearlyapp.com/terms" className="link"> Términos y Condiciones </a> 
                    y aceptas nuestra 
                    <a href="https://wearlyapp.com/privacy" className="link"> Política de Privacidad </a>.
                  </p>
                </>
              ) : (
                <>
                  <div className="button-container" style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                    <button 
                      className="integrate-button" 
                      onClick={() => window.open('https://www.tiendanube.com/tienda-aplicaciones-nube/wearly', '_blank')}
                      style={{
                        height: '60px',
                        width: '100%',
                        border: '1.5px solid #007bff',
                        backgroundColor: 'white',
                        color: '#007bff',
                        borderRadius: '10px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',
                        fontWeight: 'bold'
                      }}
                    >
                      Integrar tu Tiendanube
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                        <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                      </svg>
                    </button>
                    <button 
                      className="waitlist-button" 
                      onClick={() => setShowWaitListForm(true)}
                      style={{
                        height: '60px',
                        width: '100%',
                        border: 'none',
                        backgroundColor: 'black',
                        color: 'white',
                        borderRadius: '10px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        fontWeight: 'bold'
                      }}
                    >
                      Unirse a la lista de espera
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          )}
        </>
      ) : (
        // This is the thank you message displayed after form submission
        <div>
          <h2>¡Gracias por unirte a la lista de espera!</h2>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <ConfettiExplosion />
          </div>
          <p>Uno de nuestros especialistas se pondrá en contacto contigo pronto.</p>
          <button className='go-back-button' onClick={goBack}>Volver al inicio</button>
        </div>
      )}
    </div>
  );
};

export default SignUpPage;