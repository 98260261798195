import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import './spinner.css';
import Landing from './Pages/Landing';
import SignUpPage from './Pages/AuthPages/JavaScript/SignUpPage';
import LogInPage from './Pages/AuthPages/JavaScript/LogInPage';
import HomePage from './Pages/DashboardPages/JavaScript/HomePage';
import Ventas from './Pages/DashboardPages/JavaScript/Ventas';
import Ajustes from './Pages/DashboardPages/JavaScript/Ajustes';
import OnboardingForm from './Pages/DashboardPages/JavaScript/OnboardingForm';
import PasswordSetup from './Pages/AuthPages/JavaScript/PasswordSetup';
import { useAuth } from './useAuth';

function PrivateRoute({ children, allowedRoles }) {
  const { user, authChecking, onboardingStatus, userRole } = useAuth();

  if (authChecking || onboardingStatus === null) {
    return <div className="spinner"></div>;
  }

  if (!user) {
    return <Navigate to="/" />;
  }

  // Allow access to /onboarding route regardless of onboarding status
  if (window.location.pathname === "/onboarding") {
    return children;
  }

  // Redirect to onboarding if onboardingStatus is false
  if (onboardingStatus === false) {
    return <Navigate to="/onboarding" />;
  }

  // Redirect to dashboard if onboarding is complete
  return children;
}

function PublicRoute({ children }) {
  const { user, authChecking, onboardingStatus } = useAuth();

  if (authChecking) {
    return <div className="spinner"></div>;
  }

  if (user) {
    if (onboardingStatus === false) {
      return <Navigate to="/onboarding" />;
    }
    return <Navigate to="/dashboard" />;
  }

  return children;
}

function App() {
  const { authChecking } = useAuth();

  if (authChecking) {
    return <div className="spinner"></div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<PublicRoute><Landing /></PublicRoute>} />
        <Route path="/join" element={<PublicRoute><SignUpPage /></PublicRoute>} />
        <Route path="/login" element={<PublicRoute><LogInPage /></PublicRoute>} />
        <Route path="/password-setup" element={<PasswordSetup />} />
        <Route path="/onboarding" element={<PrivateRoute allowedRoles={['brand', 'admin']}><OnboardingForm /></PrivateRoute>} />
        <Route path="/dashboard" element={<PrivateRoute allowedRoles={['brand', 'admin']}><HomePage /></PrivateRoute>} />
        <Route path="/ventas" element={<PrivateRoute allowedRoles={['brand', 'admin']}><Ventas /></PrivateRoute>} />
        <Route path="/ajustes" element={<PrivateRoute allowedRoles={['brand', 'admin']}><Ajustes /></PrivateRoute>} />
      </Routes>
    </Router>
  );
}

export default App;